class MapperService {

    /**
     * Mapping from the response partner object to edit partner form
     * @param partnerObject
     * @returns {{last_name: (string|*), first_name: (string|*)}}
     */
    toEditPartnerForm(partnerObject) {
        const form = {};

        if (partnerObject.contacts && partnerObject.contacts.length > 0) {
            form.first_name = partnerObject.contacts[0].first_name;
            form.last_name = partnerObject.contacts[0].last_name;
            if (partnerObject.contacts[0].emails[0]) {
                form.email = partnerObject.contacts[0].emails[0].email;
            }
            if (partnerObject.contacts[0].phonenumbers[0]) {
                form.number = partnerObject.contacts[0].phonenumbers[0].number;
            }
        }

        if (partnerObject.address) {
            form.address_1 = partnerObject.address.address_1;
            form.address_2 = partnerObject.address.address_2;
            form.city = partnerObject.address.city;
            form.postal_code = partnerObject.address.postal_code;
            form.state = partnerObject.address.state;
        }

        return form;
    }

    /**
     * Modified partnerObject from the edit partner form
     * @param partnerForm
     * @param partnerObject
     * @returns partnerObject
     */
    fromEditPartnerForm(partnerForm, partnerObject) {
        if ((partnerForm.address_1 || '').length > 0) {
            partnerObject.address = {
                address_1: partnerForm.address_1,
                address_2: partnerForm.address_2,
                city: partnerForm.city,
                country: "US",
                postal_code: partnerForm.postal_code,
                state: partnerForm.state
            };
        }

        const contactDefined = partnerForm.last_name || partnerForm.email || partnerForm.number;

        if (contactDefined) {
            const contactData = {
                    first_name: partnerForm.first_name,
                    last_name: partnerForm.last_name,
                    partner_uuid: partnerObject.uuid,
                    name: partnerForm.first_name + ' ' + partnerForm.last_name
                },
                email = {email: partnerForm.email},
                phoneNumber = {number: partnerForm.number, primary: true, type: 'work'};

            if (!partnerObject.contacts) {
                partnerObject.contacts = [];
            }
            if (partnerObject.contacts[0]) {
                partnerObject.contacts[0] = Object.assign({}, partnerObject.contacts[0], contactData);
                partnerObject.contacts[0].emails[0] = Object.assign({}, partnerObject.contacts[0].emails[0], email);
                partnerObject.contacts[0].phonenumbers[0] = Object.assign({}, partnerObject.contacts[0].phonenumbers[0], phoneNumber);
            } else {
                contactData.phonenumbers  = [phoneNumber];
                contactData.emails  = [email];

                partnerObject.contacts.push(contactData);
            }


        }

        return partnerObject;
    }

}

export default new MapperService();
