<template>
  <div class="container-lg kap-container">
    <div v-if="loadingPage" class="content"> Loading...</div>
    <div v-if="!loadingPage" class="content">
      <div id="my-account-view">
        <h3 class="page-title">My Account</h3>
        <div class="border-bottom"></div>
        <div class="row pt-3">
          <div class="col-md-12 col-lg-6">
            <label><strong>CONTACT</strong></label>
            <div class="form-group">
              <label>First Name</label>
              <input v-model="partnerForm.first_name" class="form-control form-control-edit" placeholder="" type="text" autocomplete="off" id="first_name">
              <div class="text-danger" v-if="isErrorNotEmpty('contacts.0.first_name', errors)">
                {{ getErrorMsg('contacts.0.last_name', errors) }}
              </div>
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input v-model="partnerForm.last_name" class="form-control form-control-edit" placeholder="" type="text" autocomplete="off" id="last_name">
              <div class="text-danger" v-if="isErrorNotEmpty('contacts.0.last_name', errors)">
                {{ getErrorMsg('contacts.0.last_name', errors) }}
              </div>
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <input v-model="partnerForm.number" class="form-control form-control-edit masked-phone" placeholder="(000) 000-0000" autocomplete="off" id="number">
              <div class="text-danger" v-if="isErrorNotEmpty('contacts.0.phonenumbers.0.number', errors)">
                {{ getErrorMsg('contacts.0.phonenumbers.0.number', errors) }}
              </div>
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input v-model="partnerForm.email" class="form-control form-control-edit" placeholder="" type="text" id="email">
              <div class="text-danger" v-if="isErrorNotEmpty('contacts.0.emails.0.email', errors)">
                {{ getErrorMsg('contacts.0.emails.0.email', errors) }}
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <label><strong>ADDRESS</strong></label>
            <div class="form-group">
              <label>Address Line 1</label>
              <input v-model="partnerForm.address_1" class="form-control form-control-edit" placeholder="" type="text" autocomplete="off" id="address_1">
              <div class="text-danger" v-if="isErrorNotEmpty('address.address_1', errors)">
                {{ getErrorMsg('address.address_1', errors) }}
              </div>
            </div>
            <div class="form-group">
              <label>City</label>
              <input v-model="partnerForm.city" class="form-control form-control-edit" placeholder="" type="text" autocomplete="off" id="city">
              <div class="text-danger" v-if="isErrorNotEmpty('address.city', errors)">
                {{ getErrorMsg('address.city', errors) }}
              </div>
            </div>
            <div class="form-group">
              <label>Zip</label>
              <input v-model="partnerForm.postal_code" class="form-control form-control-edit" placeholder="" type="text" autocomplete="off" id="postal_code">
              <div class="text-danger" v-if="isErrorNotEmpty('address.postal_code', errors)">
                {{ getErrorMsg('address.postal_code', errors) }}
              </div>
            </div>
            <div class="form-group">
              <label>State</label>
              <select class="form-control form-control-edit" id="state" v-model="partnerForm.state">
                <option v-for="state in stateOptions" v-bind:key="state" :value="state.value">{{ state.text }}</option>
              </select>
              <div class="text-danger" v-if="isErrorNotEmpty('address.state', errors)">
                {{ getErrorMsg('address.state', errors) }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <button type="button" v-on:click="submitRecord" class="btn btn-dark" :disabled="submitting" id="submit_button">
              <span v-show="submitting" class="spinner-border spinner-border-sm"></span> Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import mapperService from '@/services/mapper.service';
import apiService from "../../plugins/api";
import {Dictionary} from "@/constants/dictionary";

export default {
  name: 'EditPartner',
  data() {
    return {
      submitting: false,
      loadingPage: true,
      partnerObject: {},
      partnerForm: {},
      errors: {},
      stateOptions: [{value: '', text: 'Select One'}, ...Dictionary.states]
    }
  },
  methods: {
    async submitRecord() {
      this.submitting = true;
      this.errors = {};
      this.partnerObject = mapperService.fromEditPartnerForm(this.partnerForm, this.partnerObject);

      try {
        const response = await apiService().updatePartner(this.partnerObject);
        this.setPartnerDataFromResponse(response);
      } catch (responseData) {
        if (responseData && responseData.status === 'error') {
          this.errors = responseData.data;
        }
      } finally {
        this.submitting = false;
      }

    },
    async fetchPartner() {
      const response = await apiService().getPartner(this.$route.params.partner_id);
      this.setPartnerDataFromResponse(response);
      this.loadingPage = false;
    },
    setPartnerDataFromResponse(response) {
      if (typeof response.data !== 'undefined') {
        this.partnerObject = response.data;
      }
      this.partnerForm = mapperService.toEditPartnerForm(this.partnerObject);
    },
    getErrorMsg(paramName, source) {
      let d = source;
      paramName.split('.').forEach(function (k) {
        if (typeof (d[k]) !== 'undefined') {
          d = d[k];
        } else {
          d = '';
        }
      });

      return d[Object.keys(d)[0]];
    },
    isErrorNotEmpty(paramName, source) {
      const value = this.getErrorMsg(paramName, source);
      return value !== null && typeof (value) != 'undefined' && value.length !== 0;
    }
  },
  mounted() {
    this.fetchPartner();
  }
};
</script>
